@import "../../../theme";

.timer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
}

.number-container {
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
}

.countdown-item {
  font-size: 3rem;
  @media (max-width: 768px) {
    font-size: 2.25rem;
  }
}

.countdown-label {
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  width: 120%;
  @media (max-width: 768px) {
    font-size: .75rem;
  }
}
