@import "../../../theme";

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  @media (max-width: 768px) {
    gap: 1.5rem;
  }
}

.faq-container .question-section {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 4rem;
  @media (max-width: 768px) {
    gap: 1rem;
  }
}

.faq-container .question-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 45%;
  min-width: 500px;
  @media (max-width: 768px) {
    min-width: 100%;
  }
}

h2 {
  margin: 0;
  font-size: 3rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}
