@import "../theme";

.section {
  position: relative;
  padding: 3rem;
  background-color: $background-color;
  color: $primary-color;
}

.section.alt {
  background-color: $background-color-alt;
}
