@import "../../../theme";

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  text-align: center;
}

.hero-content .logo {
  font-size: 4.5rem;
  color: $primary-color;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 3rem;
  }
}

.hero-content .date {
  font-size: 2rem;
  color: $primary-color;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.hero-content .main-action {
  font-size: 2rem;
  color: $primary-color;
  background-color: $secondary-color;
  padding: 0.75rem 1.75rem;
  border-radius: 3rem;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.hero-content a.main-action {
  background-color: $secondary-color;
  text-decoration: none;

  padding: 0.5rem 2.5rem;
  border-radius: 3rem;
  border: solid $secondary-color 4px;

  transition: all 0.1s ease;
}

.nav-links a.nav-item:hover {
  color: $secondary-color;
}

.hero-content a.main-action:hover {
  color: $secondary-color;
  background-color: inherit;
}
