@import "../../theme";

nav {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
}

.nav-container {
  background-color: $header-background-color;
  color: $primary-color;
  width: calc(100% - 4rem);
  height: $navbar-height;
  z-index: 1000;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  list-style-type: none;
  @media (max-width: 768px) {
    gap: 1.5rem;
  }
}

.nav-links a.nav-item {
  font-size: 1.25rem;
  color: $primary-color;
  text-decoration: none;
  transition: all 0.1s ease;
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.nav-links a.main-action {
  background-color: $secondary-color;

  padding: 0.5rem 1.5rem;
  border-radius: 3rem;
  border: solid $secondary-color 2px;
  position: relative;
  z-index: 1;
}

.nav-links a.nav-item:hover {
  color: $secondary-color;
}

.nav-links a.main-action:hover {
  color: $secondary-color;
  background-color: inherit;
}

.logo {
  font-size: 1.875rem;
  font-weight: bolder;
  margin: 0;
  user-select: none;
  color: $primary-color;
  cursor: pointer;
}

.nav-item {
  text-decoration: none;
}

.secondary-color {
  color: $secondary-color;
}

.dropdown-menu {
  position: absolute;
  background-color: $header-background-color;
  color: $primary-color;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: -1000;

  opacity: 0;
  transform: translate(-1rem, -100%);
  transition: all 0.2s ease;
}

.dropdown-menu.active {
  opacity: 1;
  transform: translate(-1rem, 0);
  z-index: 0;
}

#chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
  transform: rotate(0deg);
}

.nav-links li:has(.active) #chevron {
  transform: rotate(180deg);
}

.dropdown-menu ul {
  list-style: none;
  padding: 1rem;
  margin: 0;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dropdown-menu .dropdown-item {
  font-size: 1.25rem;
  color: $primary-color;
  text-decoration: none;
  transition: all 0.1s ease;
  cursor: pointer;
}

.dropdown-menu a:hover {
  color: $secondary-color;
}

.nav-container img {
  width: 3.25rem;
}
