@import "../../../theme";

.partners-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

h2 {
  margin: 0;
  font-size: 3rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.partners-container .partner-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  max-width: 1300px;
}

.partner-container {
  background-color: #dbdbdb;
  box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-container:hover {
  transform: scale(1.05);
}

.partners-container .buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.partners-container .buttons-container * {
  box-sizing: border-box;
  cursor: pointer;
  padding: 1rem 2rem;
  border-radius: 3rem;
  font-size: 1.75rem;
  transition: all 0.2s ease;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
}

.partners-container .buttons-container .main-button {
  background-color: $secondary-color;
  border: 4px solid $secondary-color;
  color: $primary-color;
}

.partners-container .buttons-container .main-button:hover {
  background-color: inherit;
  color: $secondary-color;
}

.partners-container .buttons-container .alt-button {
  border: 4px solid $primary-color;
}

.partners-container .buttons-container .alt-button:hover {
  color: $secondary-color;
  border-color: $secondary-color;
}
