@import "../../../theme";

.members-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.members-container .members-title {
  font-size: 3.125rem;
  color: $primary-color;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 2.25rem;
  }
}

.members-container .members {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  max-width: 1200px;
}

.members-container .member {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: $background-color;
  border-radius: 1.25rem;
}

.members-container .member .img-container {
  width: 190px;
  height: 180px;
  border-radius: 0.75rem;
  overflow: hidden;
}

.members-container .member img {
  transform: scale(1);
  -webkit-transform: translateZ(0);
  transition: transform 0.15s ease-in-out;
}

.members-container .member:hover img {
  transform: scale(1.2);
}

.members-container .member h3 {
  font-size: 1rem;
  font-weight: bold;
  color: $primary-color;
  margin: 0;
  @media (max-width: 768px) {
    font-size: .875rem;
  }
}

.members-container .member p {
  font-size: .875rem;
  font-weight: lighter;
  color: $primary-color;
  margin: 0;
}

.members-container .member .socials {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $primary-color;
  height: 35px;
}
