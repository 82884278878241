@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import "./theme";

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: $navbar-height;
  height: calc(100vh - $navbar-height);
  background-color: $background-color;
}

/* Webkit Scrollbar Styles (Chrome, Safari) */
html::-webkit-scrollbar-track {
  background: $background-color;
}

html::-webkit-scrollbar-thumb {
  background-color: $background-color-alt;
}

/* Firefox Scrollbar Styles */
html {
  scrollbar-color: $background-color-alt $background-color;
}
