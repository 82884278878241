@import "../../../theme";

.affiliated-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.affiliated-container .affiliated-title {
  font-size: 3.125rem;
  color: $primary-color;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 2.25rem;
  }
}

.affiliated-container .branches {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.affiliated-container .branch {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  border-radius: 1.5rem;
  padding: 2rem 3rem;
  background-color: $background-color;
  width: 400px;
  min-height: 500px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    min-height: 400px;
  }
}

.affiliated-container h2 {
  font-size: 2.5rem;
  color: $primary-color;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 1.625rem;
  }
}

.affiliated-container p {
  font-size: 1.25rem;
  flex: 1;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.affiliated-container .main-button {
  box-sizing: border-box;
  cursor: pointer;
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
  font-size: 1.25rem;
  transition: all 0.2s ease;
  color: $primary-color;
  text-decoration: none;

  background-color: $secondary-color;
  border: 4px solid $secondary-color;
}

.affiliated-container .main-button:hover {
  background-color: inherit;
  color: $secondary-color;
}


