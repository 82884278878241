@import "../../../theme";

.joinus-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.joinus-container .joinus-text {
  width: 37%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: start;
  @media (max-width: 768px) {
    min-width: 100%;
  }
}

.joinus-container h2 {
  font-size: 3rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.joinus-container p {
  font-size: 1.25rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.joinus-container .joinus-image {
  height: fit-content;
  width: 45%;
  min-width: 400px;
  overflow: hidden;
  border-radius: 2rem;
  @media (max-width: 768px) {
    min-width: 100%;
  }
}

.joinus-container .joinus-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: left top;
  border-radius: 2rem;
}

.joinus-container .buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
}

.joinus-container .buttons-container * {
  box-sizing: border-box;
  cursor: pointer;
  padding: 0.625rem 1.5rem;
  border-radius: 3rem;
  font-size: 1.75rem;
  transition: all 0.2s ease;
}

.joinus-container .buttons-container .main-button {
  background-color: $secondary-color;
  border: 4px solid $secondary-color;
}

.joinus-container .buttons-container .main-button:hover {
  background-color: inherit;
  color: $secondary-color;
}

.joinus-container .buttons-container .alt-button {
  border: 4px solid $primary-color;
}

.joinus-container .buttons-container .alt-button:hover {
  color: $secondary-color;
  border-color: $secondary-color;
}
