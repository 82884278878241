@import "../../../theme";

.info-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 3rem;
}

.info-content .main-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.info-content .info-text {
  width: 37%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: start;
  @media (max-width: 768px) {
    min-width: 100%;
  }
}

.info-content h2 {
  font-size: 3rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.info-content p {
  font-size: 1.25rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.info-content .info-image {
  width: 45%;
  min-width: 400px;
  overflow: hidden;
  @media (max-width: 768px) {
    min-width: 100%;
  }
}

.info-content .info-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
}

.info-content .stats-container {
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  flex-wrap: wrap;
}

.info-content .stat-box {
  width: 300px;
  height: 330px;
  background-color: $background-color;
  border-radius: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  justify-content: center;
}

.info-content .stat-description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-content .stat-description * {
  margin: 0;
}

.info-content .stat-description h2 {
  font-size: 3.5rem;
  line-height: 1;
}

.info-content .stat-description p {
  font-size: 2.25rem;
}
