@import "../../../theme";

.question-container {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 2rem;
  background-color: $background-color;
  width: 100%;
  border-radius: 1.5rem;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.question-container .first-row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    gap: 1rem;
  }
}

.question-container .answer {
  margin-top: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.2s linear;
}

.question-container.open .answer {
  overflow: hidden;
  margin-top: 2rem;
}

.question-container .question-text {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.question-container .answer-text {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.question-container #chevron {
  transform: rotate(0deg);
  transition: all 0.2s linear;
}

.question-container.open #chevron {
  transform: rotate(180deg);
}

h2 {
  margin: 0;
  font-size: 3rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}
