@import "../../../theme";

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  text-align: center;
}

.hero-content .title {
  font-size: 6rem;
  color: $primary-color;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 4rem;
  }
}
