@import "../../theme";

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  padding-bottom: 1rem;
  gap: 4rem;
  background-color: $header-background-color;
  color: $primary-color;
}

.footer-container .title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container .title {
  margin: 0;
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 1;
  @media (max-width: 768px) {
    font-size: 2.25rem;
  }
}

.footer-container .subtitle {
  margin: 0;
  font-size: 2.25rem;
  font-weight: 300;
  @media (max-width: 768px) {
    font-size: 1.875rem;
  }
}

.footer-nav-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  width: 80%;
  justify-content: space-around;
}

.footer-nav-container .nav-category {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-nav-container .label {
  margin: 0;
  padding: 0.5rem 0;
  font-size: 1.5rem;
}

.footer-nav-container .nav-option {
  margin: 0;
  font-size: 1.25rem;
  user-select: none;
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  padding: 0.5rem 0;
  transition: all 0.1s ease;

  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer-nav-container .nav-option:hover {
  color: $secondary-color;
}

.footer-container .copyright {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
